// 简体中文
// 创建文件zh/index.js
const zh = {
  // 导航栏
  hegui: "合规系统",
  guanli: "管理系统",
  zhaopin: "招聘系统",
  baogao: "自动化报告",
  shuju: "数据中心",
  zhuce: "企业服务",
  denglu: "注册/登录",
  //  首页
  dingyi: "极致的人力资源合规自动化SAAS系统",
  kouhao: "护航企业发展从这里开始",
  "jianshu-1": "简化复杂的劳动法和监管标准，自动化匹配员工职业全周期风险管理",
  "jianshu-2": "完成交易所需的人力资源合规审查背书与认证",
  "jianshu-3": "保驾护航企业安全持久发展",
  hezuoshang: "COMDOC人力合规平台正在被越来越多的企业信任",
  "maintitle-1": "自动化",
  "mainsubtitle-1": "员工全流程管理合规自动化",
  "mainjianshu-1":
    "ComDoc平台将员工全职业生命周期的管理与劳动用工风险点自动匹配结合，员工异动即触发风险点监控，如有风险点需要履约，系统自动推送定制化的合规文本，适时生成数字化档案，实现即时留痕存证。",
  "maintitle-2": "智能化",
  "mainsubtitle-2": "24小时风险扫描监控",
  "mainjianshu-2":
    "系统结合法律法规以及企业的行业规模和所处地区，适时扫描监测企业存在的用工风险以及企业自身风险，发现问题即发出预警。同时对员工入在离过程中应履行的合规义务适时进行扫描，未履约及未合规项发出预警，系统即追踪直至风险整改消除。",
  "maintitle-3": "专业资质",
  "mainsubtitle-3": "完成交易所需的合规背书与认证",
  "mainjianshu-3":
    "系统自带的数字化用工风险自查诊断、对接律师工作台完成的人力资源合规审计、合规体系与审核等，使企业适时获得强大专家团队的支持，支持业务进展，完成在招投标、企业并购和上市时所需的合规审查背书与认证。",
  "maintitle-4": "大数据",
  "mainsubtitle-4": "56000部劳动法规  /  618万法条  /  800个合规风险点",
  "mainjianshu-4":
    "覆盖全国34个省级行政区、333个城市，国家和地方的有效劳动法律、行政法规、部门规章和规范性文件56000部，约618万条法条，国企、民企及各行业需完善的合规风险点800个（并持续更新中），支持外规内化。",
  "maintitle-5": "自动化",
  "mainsubtitle-5": "合规义务落实协作工作台",
  "mainjianshu-5":
    "系统结合法律法规以及企业的行业规模和所处地区，适时扫描监测企业存在的用工风险以及企业自身风险，发现问题即发出预警。团队通过整改工作台进行项目协作，可发起律师线上审查与指导，完成合规体系建立和整改任务。",
  "maintitle-6": "资源对接",
  "mainsubtitle-6": "定制化企业人力资源合规体系与指南",
  "mainjianshu-6":
    "通过合规整改任务工作台的审批，审核和协作功能，依据数据资源，线上专业律师协助，以及在线AI助手，以低成本快速高效定制化完成企业人力资源合规体系和指南。",
  "maintitle-7": "价值收益",
  "mainsubtitle-7": "成本降低80%",
  "mainjianshu-7":
    "依靠强大的数据库支持，以及人工智能技术支持的合规自动化引擎，帮助企业降低追踪复杂劳动法规体系的困难，以及投入时间和资金的学习成本。管理中不确定性而带来的管理成本，如人员、沟通、打印以及劳动冲突等巨大的成本。",
  "maintitle-8": "你知道吗？",
  "mainsubtitle-8": "实现用人管理0风险",
  "mainjianshu-8":
    "通过平台的法规大数据、风险点、合规指南、风险扫描监控、员工合规管理自动化、数字档案存证出证、劳动法专家支持等，帮助企业实现合规专业化以及用工管理0风险。",
  // 首页-法律法规标准
  faguititle: "法律法规和标准",
  "faguititle-1": "劳动法律法规",
  "faguimain-1":
    "覆盖全国34个省级行政区、333个城市，国家和地方的有效劳动法律、行政法规、部门规章和规范性文件56000部，约618万条法条，国企、民企及各行业需完善的合规风险点800个（并持续更新中），支持外规内化。",
  link: "了解更多",
  "faguititle-2": "和谐劳动关系",
  "faguimain-2":
    "按全国各地评价和谐劳动关系企业的评审标准，帮助企业进行严格按照和谐劳动关系评价进行审查，成为和谐用工关系的企业达到此标准。",
  "faguititle-3": "ISO 37301:2021",
  "faguimain-3":
    "国际标准化组织发布和实施的A类管理体系标准，适用于全球任何类型、规模、性质和行业的组织。基于合规治理原则，为组织建立并运行合规管理体系提供整套解决方案。适用于第三方合规体系认证准则。",
  // 首页-方案
  fangan: "方案",
  "fangan-1": "为",
  "fangan-2": "组织",
  "fangan-3": "和",
  "fangan-4": "个人",
  "fangan-5": "提供解决方案",
  zuzhi: "组织",
  geren: "个人",
  qiye: "企业",
  "qiye-1":
    "为各行业、各种类型、各种规模的企业提供定制化的人力合规自动化解决方案，无论您是处在创业阶段、发展阶段还是成熟阶段，会帮助您的企业低成本高效率的建立或者完善员工关系。",
  zixungongsi: "人力资源服务与管理咨询公司",
  "zixungongsi-1":
    "帮助人力资源公司实现对其用户企业的数字化管理。通过平台对用户企业进行合规化和专业化赋能，在线实现用户企业信息、业务和人员管理。",
  lvshi: "律师事务所",
  "lvshi-1":
    "平台专属的律师平台，对接企业用户，实现资源整合与对接，律师平台可以实现律师事务所任务分配管理，在线进行企业用户的文书审查与咨询等业务。进行需求准确对接与任务完成效率。",
  relicongyezhe: "人力资源从业者",
  "relicongyezhe-1":
    "ComDoc平台的丰富的资源、人力资源合规系统，专业工具、解决方案、各类自动化报告、行业数据等，为人力资源从业者包括企业人力资源管理人员、决策者等，提供职场问题和个人成长所需的强有力的专业资源和顾问支持。",
  yuangonggeren: "员工个人",
  "yuangonggeren-1":
    "员工在企业整个职业周期中所发生应进行履约的节点，ComDoc员工端帮助企业与员工之间快速完成履约匹配，实现线上数字化留痕存档。同时帮助员工实现整个职业生涯中的个人职业生涯数字档案。",
  // 首页-资源
  ziyuan: "资源",
  "ziyuantitle-1": "了解 ComDoc 和",
  "ziyuantitle-2": "产品 ",
  "ziyuantext-1": "关于海富德",
  "ziyuantext-2": "COMDOC产品介绍",
  "ziyuantext-3": "如何建立人力资源合规体系",
  qiyerongyu:'企业荣誉',
  // 首页-大数据
  shujutitle:'ComDoc平台值得信赖',
  "shujutext-1":'实现员工合规履约自动化',
  "shujutext-2":'人力资源管理系统集成平台',
  "shujutext-3":'无论您的公司在初创期、高速发展期还是成熟变革期；也无论您所属哪种行业或者类型，都需要管理您的员工。',
  "shujutext-4":'在这里您会快速得到智能化匹配的定制方案，系统简化复杂的劳动法和监管标准，',
  "shujutext-5":'自动化匹配员工职业全周期风险管理，保驾护航企业安全持久发展。',
  'zhucebtn':'注册 ComDoc',
  denglubtn:'去登录',
  'unit-1':'家',
  'unit-2':'天',
  'unit-3':'次',
  zhuceqiye:'注册企业数',
  yunyingtianshu:'安全运营天数',
  pingtaifangwen:'平台总访问量',
};
export default zh;
