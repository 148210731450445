import Vue from "vue";
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css'; // 引入Element UI样式
import VueRouter from "vue-router";
import { routerM } from "./routerM";
import { routerPC } from "./routerPC";
Vue.use(ElementUI);
Vue.use(VueRouter);

// 是否为手机端
const ISMOBILE = function () {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
};
// const routes = [
//   {
//     path: '/',
//     name: 'home',
//     component: Home
//   },
//   {
//     path: '/about',
//     name: 'about',
//     component: () => import('../views/AboutView.vue')
//   }
// ]

const router = new VueRouter({
  mode: "history",
  // 这里判断后选择使用移动端的路由还是pc端的路由
  routes: ISMOBILE() ? routerM : routerPC,
});
// router.beforeEach((to, from, next) => {
//   // 如果目标路由不存在，重定向到404页面
//   if (to.matched.length === 0) {
//     next("/404");
//   } else {
//     next();
//   }
// });

router.beforeEach((to, from, next) => {
  // 设置页面title
  document.title = to.meta.title;
 
  // 清除当前head中的meta标签
  //const elements = document.querySelectorAll('meta[data-vue-router=true]');
  //Array.from(elements).forEach(el => el.parentNode.removeChild(el));
 

    if(to.meta){
      const head = document.getElementsByTagName('head')[0];
    // 删除现有名称为 "keywords" 和 "description" 的 meta 标签
    const existingTitleMeta = document.querySelector('meta[name="title"]');
    if (existingTitleMeta) {
      existingTitleMeta.remove(); // 移除现有的关键词 meta 标签
    }
    const existingKeywordsMeta = document.querySelector('meta[name="keywords"]');
    if (existingKeywordsMeta) {
        existingKeywordsMeta.remove(); // 移除现有的关键词 meta 标签
    }
    const existingDescriptionMeta = document.querySelector('meta[name="description"]');
    if (existingDescriptionMeta) {
        existingDescriptionMeta.remove(); // 移除现有的描述 meta 标签
    }
    
    // 创建并设置新的关键词和描述 meta 标签
    const titleMeta = document.createElement('meta');
    titleMeta.setAttribute('name', 'title');
    titleMeta.setAttribute('content', to.meta.title);
    head.appendChild(titleMeta); // 添加新的关键词 meta 标签

    // 创建并设置新的关键词和描述 meta 标签
    const keywordsMeta = document.createElement('meta');
    keywordsMeta.setAttribute('name', 'keywords');
    keywordsMeta.setAttribute('content', to.meta.keywords);
    head.appendChild(keywordsMeta); // 添加新的关键词 meta 标签

    const descriptionMeta = document.createElement('meta');
    descriptionMeta.setAttribute('name', 'description');
    descriptionMeta.setAttribute('content', to.meta.description);
    head.appendChild(descriptionMeta); // 添加新的描述 meta 标签

    next();
    }
});

export default router;
