<template>
  <div id="app">
    <router-view v-wechat-title="$route.meta.title" />
  </div>
</template>
<script>
export default {
 
}
</script>
<style>
html,
body {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", "PingFang SC", "Noto Sans", "Noto Sans CJK SC",
    "Microsoft YaHei", "微软雅黑", sans-serif !important;
}
/* body::-webkit-scrollbar {
  width: 0;
} */
</style>
