import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import less from "less";
// import 'lib-flexible'
import "amfe-flexible";
import "@/assets/css/common.css";
import { iconurl } from "@/utils/config.js";
import layer from "layui-layer";
import i18n from './language'
import VueWechatTitle from 'vue-wechat-title'

// 全局方法挂载
Vue.prototype.iconurl = iconurl;
// 链接邮箱地址
Vue.prototype.$conectEmail = function (email) {
  window.open(`https://best.ai-hrcompliance.com/?code=register&email=${email}`,'_blank')
}
// 了解更多
Vue.prototype.$learnmore = function () {
  window.open(`https://best.ai-hrcompliance.com`,'_blank')
}
// 注册
Vue.prototype.$register = function () {
  window.open('https://best.ai-hrcompliance.com/?code=register', '_blank')
}
Vue.use(less);
Vue.use(VueWechatTitle);

Vue.config.productionTip = false;

new Vue({
  i18n, // 国际化挂载
  router,
  store,
  render: (h) => h(App),
  // //这里与vue.config.js中的事件名相对应
  // mounted () {
  //   document.dispatchEvent(new Event('render-event'))
  // }
}).$mount("#app");
