// routerM.js
export const routerM = [
  {
    path: "*",
    component: () => import("../../views/mobile/home.vue"),
    hidden: true,
    meta:{
      title:"海富德人力合规—极致的人力资源合规自动化SAAS系统",
      keywords: '人力合规,HR SAAS,人力资源SAAS,用工风控管理,劳动风险,劳动风控管理,劳动法规库,和谐劳动关系,背景调查,人力合规审计',
      description: '海富德人力合规—极致的人力资源合规自动化SAAS系统，不仅一站式整合员工入职、在职、离职中的各中风险点管理，提供员工电子花名册、人力资源合规风险工作台、合规风险诊断报告竺套件，更提供COMDOC 劳动合同卫士、员工手册、数字化人事规章体系、职位说明书大师、电子工资条、劳动法规库检索、背景调查、全国各地员工和谐评价报告等组织管理产品，让人力资源管理理更高效。'
      ,key:'home'
    },
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../../views/mobile/home.vue"),
    meta:{
      title:"海富德人力合规—极致的人力资源合规自动化SAAS系统",
      keywords: '人力合规,HR SAAS,人力资源SAAS,用工风控管理,劳动风险,劳动风控管理,劳动法规库,和谐劳动关系,背景调查,人力合规审计',
      description: '海富德人力合规—极致的人力资源合规自动化SAAS系统，不仅一站式整合员工入职、在职、离职中的各中风险点管理，提供员工电子花名册、人力资源合规风险工作台、合规风险诊断报告竺套件，更提供COMDOC 劳动合同卫士、员工手册、数字化人事规章体系、职位说明书大师、电子工资条、劳动法规库检索、背景调查、全国各地员工和谐评价报告等组织管理产品，让人力资源管理理更高效。'
      ,key:'home'
    },
  },
  {
    path: "/compliance",
    name: "compliance",
    component: () => import("../../views/mobile/compliance.vue"),
    meta:{
      title:"人力资源合规系统-ComDoc自动风险扫描预警机制-员工入在离全职业周期风险管理",
      keywords: '智能风险扫描,合规风险扫描预警,人力合规指南,劳动用工风险点数据库,人力合规义务工作台,律师在线审查,线上民主流程,员工自动化应用,员工职业全周期合规管理,字化留痕存证系统',
      description: '海富德人力合规—极致的人力资源合规自动化SAAS系统，通过背景调查实现人的合规，通过员工入在离合规管理自动化实现人力资源业务合规。'
      ,key:'compliance'
    },
  },
  {
    path: "/management",
    name: "management",
    component: () => import("../../views/mobile/management.vue"),
    meta:{
      title:"人力资源线上管理工具-全流程全模块智能HR智能管理工具",
      keywords: '人力资源管理系统,职位说明书大师,劳动合同卫士,员工手册定制器,劳动法计算器,电子工资条,劳动法规库,HR制度流程工具包,结构化行为面试问卷',
      description: '海富德人力合规—极致的人力资源合规自动化SAAS系统，提供多种人力资源智能管理工具，包括职位说明书大师、员工手册定制器、电子工资条、全国劳动法规库、HR制度流程工具大全、数字面试官、结构化行为面试问卷，让人力资源管理更高效。'
      ,key:'management'
    },
  },
  {
    path: "/recruitment",
    name: "recruitment",
    component: () => import("../../views/mobile/recruitment.vue"),
    meta:{
      title:"ComDoc数字面试官-数字化结构化行为面试问卷系统-三大“智能”助力招聘效能提升三倍-四大核心技术-实现人才适时获取与精准匹配",
      keywords: '结构化行为面试SBI,组织职位管理,自动化面试问卷,视频面试,面试总结报告,智能简历,人才库管理',
      description: '海富德人力合规—极致的人力资源合规自动化SAAS系统，通过建立数字化的结构化行为面试问卷系统，消除面试中的主观偏见，基于科学数据做出聘用决策。三大“智能”助力招聘效能提升三倍，四大核心技术，实现人才适时获取与精准匹配。'
      ,key:'recruitment'
    },
  },
  {
    path: "/reporting",
    name: "reporting",
    component: () => import("../../views/mobile/reporting.vue"),
    meta:{
      title:"ComDoc风险核查报告-背景调查-员工信息核查-用工风控诊断报告-人力合规审计",
      keywords: '人力合规报告,人力资源合规风险扫描报告,劳动用工风险自查诊断报告,人力资源合规指引,人力资源合规审计报告,和谐劳动关系评价报告,背景调查,背景调查报告,员工背景调查报告,员工满意度线上调研,线上员工满意度报告',
      description: '海富德人力合规—极致的人力资源合规自动化SAAS系统，基于大数据、计算模型，系统为您生成定制化的专业报告。包括人力资源合规审计报告、背景调查报告、员工满意度调研报告、员工管理风险扫描报告和谐劳动关系评价报告。'
      ,key:'reporting'
    },
  },
  // { path: '/404', name: 'NotFound',  component: () => import("../../views/mobile/NotFound.vue"), },
  { path: '*', redirect: '/home' }
];
