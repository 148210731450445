// 英文
// 创建文件en/index.js
const en = {
  // 导航栏
  hegui: "Compliance System",
  guanli: "Management System",
  zhaopin: "Recruitment System",
  baogao: "Automated Reporting",
  shuju: "Data Center",
  zhuce: "Register",
  denglu: "Login",
  //  首页
  dingyi: "Ultimate Human Resources Compliance Automated SAAS System",
  kouhao: "The development of escort enterprises starts from here",
  "jianshu-1":
    "Simplify complex labor laws and regulatory standards, automate and match employee career lifecycle risk management",
  "jianshu-2":
    "Endorsement and certification of human resource compliance review required to complete the transaction",
  "jianshu-3":
    "Safeguarding the safe and sustainable development of enterprises",
  hezuoshang:
    "The COMDOC human resources compliance platform is being trusted by more and more enterprises",
  "maintitle-1": "Automation",
  "mainsubtitle-1": "Employee full process management compliance automation",
  "mainjianshu-1":
    "The ComDoc platform combines the management of employees' entire career life cycle with automatic matching of labor employment risk points. Employee changes trigger risk point monitoring. If there are risk points that need to be fulfilled, the system automatically pushes customized compliance text, generates digital archives in a timely manner, and achieves real-time tracking and certification.",
  "maintitle-2": "Intelligent",
  "mainsubtitle-2": "24-hour risk scanning monitoring",
  "mainjianshu-2":
    "The system combines laws and regulations, as well as the industry scale and location of the enterprise, to timely scan and monitor the employment risks of the enterprise and its own risks, and issue warnings when problems are found. At the same time, timely scan the compliance obligations that employees should fulfill during the entry and exit process, issue warnings for non compliance and non compliance items, and the system will track until the risk is rectified and eliminated.",
  "maintitle-3": "Professional qualifications",
  "mainsubtitle-3":
    "Compliance endorsement and certification required to complete the transaction",
  "mainjianshu-3":
    "The system's built-in digital employment risk self inspection and diagnosis, as well as the human resource compliance audit, compliance system and audit completed by the lawyer workbench, enable enterprises to receive timely support from a strong expert team, support business progress, and complete the compliance review endorsement and certification required for bidding, enterprise mergers and acquisitions, and listing.",
  "maintitle-4": "Big data",
  "mainsubtitle-4":
    "56000 labor regulations  /  6.18 million legal provisions  /  800 compliance risk points",
  "mainjianshu-4":
    "Covering 34 provincial-level administrative regions and 333 cities across the country, there are 56000 effective labor laws, administrative regulations, departmental rules, and normative documents at the national and local levels, with approximately 6.18 million laws and regulations. There are 800 compliance risk points that need to be improved in state-owned enterprises, private enterprises, and various industries (and are continuously being updated), supporting the internalization of external regulations.",
  "maintitle-5": "Automation",
  "mainsubtitle-5":
    "Compliance Obligation Implementation Collaboration Workbench",
  "mainjianshu-5":
    "The system combines laws and regulations, as well as the industry scale and location of the enterprise, to timely scan and monitor the employment risks of the enterprise and its own risks, and issue warnings when problems are found. The team collaborates on the project through the rectification workbench, and can initiate online review and guidance from lawyers to complete the establishment of compliance systems and rectification tasks.",
  "maintitle-6": "Resource docking",
  "mainsubtitle-6":
    "Customized Enterprise Human Resources Compliance System and Guidelines",
  "mainjianshu-6":
    "Through the approval, review, and collaboration functions of the compliance rectification task workbench, based on data resources, online professional lawyer assistance, and online AI assistants, we can quickly and efficiently customize the enterprise human resources compliance system and guidelines at a low cost.",
  "maintitle-7": "Value return",
  "mainsubtitle-7": "Cost reduction of 80%",
  "mainjianshu-7":
    "Relying on powerful database support and compliance automation engines supported by artificial intelligence technology, we help enterprises reduce the difficulty of tracking complex labor regulatory systems and the cost of investing time and money in learning. The management costs brought about by uncertainty in management, such as huge costs of personnel, communication, printing, and labor conflicts.",
  "maintitle-8": "Do you know?",
  "mainsubtitle-8": "Realize zero risk in personnel management",
  "mainjianshu-8":
    "Through the platform's regulatory big data, risk points, compliance guidelines, risk scanning and monitoring, employee compliance management automation, digital archive certification and issuance, and labor law expert support, we help enterprises achieve compliance specialization and zero risk employment management.",
  // 首页-法律法规标准
  faguititle: "Laws, regulations and standards",
  "faguititle-1": "Labor laws and regulations",
  "faguimain-1":
    "Covering 34 provincial-level administrative regions and 333 cities across the country, there are 56000 effective labor laws, administrative regulations, departmental rules, and normative documents at the national and local levels, with approximately 6.18 million laws and regulations. There are 800 compliance risk points that need to be improved in state-owned enterprises, private enterprises, and various industries (and are continuously being updated), supporting the internalization of external regulations.",
  link: "Learn more",
  "faguititle-2": "Harmonious labor relations",
  "faguimain-2":
    "According to the evaluation standards for harmonious labor relations enterprises in various parts of the country, we will assist enterprises in conducting strict reviews in accordance with the evaluation of harmonious labor relations, and achieve this standard for enterprises with harmonious labor relations.",
  "faguititle-3": "ISO 37301:2021",
  "faguimain-3":
    "The Class A management system standard issued and implemented by the International Organization for Standardization is applicable to organizations of any type, size, nature, and industry worldwide. Based on the principles of compliance governance, provide a complete solution for the organization to establish and operate a compliance management system. Applicable to third-party compliance system certification guidelines.",

  // 首页-方案
  fangan: "Programme",
  "fangan-1": "For",
  "fangan-2": "organizations ",
  "fangan-3": "and ",
  "fangan-4": "individuals",
  "fangan-5": "Provide solutions",
  zuzhi: "Organization",
  geren: "Personal",
  qiye: "Enterprise",
  "qiye-1":
    "Providing customized human compliance automation solutions for enterprises of various industries, types, and sizes, whether you are in the entrepreneurial, development, or mature stage, will help your company establish or improve employee relationships with low cost and efficiency.",
  zixungongsi: "Human Resources Services and Management Consulting Company",
  "zixungongsi-1":
    "Assist human resources companies in achieving digital management of their user enterprises. Empowering user enterprises through standardization and specialization through the platform, and achieving online management of user enterprise information, business, and personnel.",
  lvshi: "Law office",
  "lvshi-1":
    "The exclusive lawyer platform of the platform connects with enterprise users, achieving resource integration and docking. The lawyer platform can achieve task allocation management for law firms, and conduct online document review and consultation services for enterprise users. Accurately align requirements and improve task completion efficiency.",
  relicongyezhe: "Human resources practitioners",
  "relicongyezhe-1":
    "The rich resources, human resource compliance system, professional tools, solutions, various automated reports, industry data, etc. of the ComDoc platform provide strong professional resources and consultant support for human resource practitioners, including enterprise human resource managers, decision-makers, etc., to address workplace issues and personal growth needs.",
  yuangonggeren: "Individual employees",
  "yuangonggeren-1":
    "At the nodes where employees should fulfill their obligations throughout the entire career cycle of the enterprise, the ComDoc employee end helps the enterprise and employees quickly complete performance matching, achieving online digital tracking and archiving. Simultaneously helping employees achieve personal career digital archives throughout their entire career.",
  // 首页-资源
  ziyuan: "Resources",
  "ziyuantitle-1": "Learn about ComDoc and",
  "ziyuantitle-2": "Product",
  "ziyuantext-1": "About Haifude",
  "ziyuantext-2": "COMDOC Product Introduction",
  "ziyuantext-3": "How to establish a human resources compliance system",
  qiyerongyu: "Corporate Honors",
  // 首页-大数据
  shujutitle: "The ComDoc platform is trustworthy",
  "shujutext-1": "Automate employee compliance and performance",
  "shujutext-2": "Human Resource Management System Integration Platform",
  "shujutext-3":
    "Whether your company is in its start-up phase, high-speed development phase, or mature transformation phase; No matter which industry or type you belong to, you need to manage your employees.",
  "shujutext-4":
    "Here you will quickly obtain customized solutions for intelligent matching, simplifying complex labor laws and regulatory standards,",
  "shujutext-5": "Automated matching of employee career full cycle risk management ensures the safe and sustainable development of the enterprise.",
  zhucebtn: "Register ComDoc",
  denglubtn: "Go log in",
  'unit-1':'homes',
  'unit-2':'days',
  'unit-3':'times',
  zhuceqiye:'Number of registered enterprises',
  yunyingtianshu:'Safe operating days',
  pingtaifangwen:'Total platform visits',
};
export default en;
